@charset "UTF-8";

/* CSS Document */
/*==============================================================
    reset
/*==============================================================*/
/*要素のフォントサイズやマージン・パディングをリセット */

html{
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 62.5%;
  line-height: 1;
}

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, ins, kbd, q, samp, small, sub, sup, var, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 100%;
}

img {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: bottom;
  font-size: 100%;
}

/*新規追加要素のデフォルトはすべてインライン要素になっているので、section要素などをブロック要素へ変更 */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

/*ol ulのマーカー（行頭記号）を表示しない */

ol, ul {
  list-style: none;
}

/*引用符の表示を出さない */

blockquote, q {
  quotes: none;
}

/*blockquote要素、q要素の前後にコンテンツを追加しないように指定 */

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

/*a要素のフォントサイズなどをリセットしフォントの縦方向の揃え位置を親要素のベースラインに揃えるように */

a {
  margin: 0;
  padding: 0;
  background: transparent;
  text-decoration: none;
  font-size: 100%;

  img {
    border: none;
  }

  &:focus {
    outline: none;
  }
}

button:focus {
  outline: none;
}

/* ins要素のデフォルトをセットし、色を変える場合はここで変更できるように */

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* mark要素のデフォルトをセットし、色やフォントスタイルを変える場合はここで変更できるように指定
mark要素は、文書内の検索結果で該当するフレーズをハイライトする際に使用 */

mark {
  background-color: #ff9;
  color: #000;
  font-weight: bold;
  font-style: italic;
}

/*テキストに打ち消し線が付くように */

del {
  text-decoration: line-through;
}

/* IEではデフォルトで点線を下線表示する設定ではないので、下線がつくように
また、マウスオーバー時にヘルプカーソルの表示が出るように */

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

/* 隣接するセルのボーダーを重ねて表示し、間隔を0に指定 */

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* 水平罫線のデフォルトである立体的な罫線を見えなく */

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
}

/* 行の高さ指定とテキストカラーを指定 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}






* {
  box-sizing:border-box;
}

a {
  color: #000;
}

button {
  cursor: pointer;
  border-radius: 0;
  border: none;
  padding: 0;
  margin: 0;
}
input {
  margin: 0;
  padding: 0;
}
