.PortalHead {
  display: flex;
  justify-content: center;
  margin-bottom: 57px;
}

.PortalHeadLogo__img {
  fill: #333;
  width: 44px;//64px;
  height: 46px;//66px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.PortalHeadLogo__txt {
  fill: #333;
  width: 138px;//201px;
  height: 18px;//27px;
  display: block;
  margin-top: 8px;//11px;
  margin-left: 7px;
}

.PortalCopyright {
  // margin-top: 83px;
  color: #666;
  font-size: 12px;
  padding-bottom: 40px;
}

/* .RelatePanelBlock__attention {
  color: #333;
  font-size: 12px;
  margin-top: 14px;

  a {
    color: #333;
    text-decoration: underline;

    &:hover {
      color: #999;
    }
  }
} */

.ua-sp {
  .PortalHead {
    padding-top: 40px;
    margin-bottom: 40px;
  }

  .PortalHeadLogo {
    transform: scale(0.8);
  }

  .PortalCopyright {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 40px;
  }

  /* .RelatePanelBlock__attention {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 10px;
  } */
}
