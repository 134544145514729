body {
  background-color: #F9F7F5;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}


button {
  background: transparent;
}

.check-res {
  display: none;
  width: 901px;

  @include res('sp') {
    width: 900px;
  }
}

.check-tablet {
  display: none;
  width: 961px;

  @include res('<', 960) {
    width: 960px;
  }
}

.show-pc {
  display: block;

  @include res('sp') {
    display: none;
  }
}

.show-sp {
  display: none;

  @include res('sp') {
    display: block;
  }
}

.img-lazy {
  background-size: cover;
  background-position: center;
}

.replace-img {
  position: relative;
  background-color: #f3f3f3;
}

.replace-img__inner {
  width: 100%;
  padding-top: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}
