/* ============================================================================

  SP時のヘッダー

============================================================================== */
.SpHeaderBgColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s $ease-out-expo;
  opacity: 0;
}

.SpHeaderBgColor--navy {
  background-color: $color-navy;
}

.SpHeaderBgColor--white {
  background-color: #fff;
}

.SpHeaderBgColor--hotel {
  background-color: #351F1B;
}

.SpHeaderBgColor--login {
  background-color: #F9F7F5;
}

.SpHeader {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 56px;
  z-index: 200;
  width: 100%;
  transition: 0.5s $ease-out-expo;

  .SpHeaderBgColor--navy {
    opacity: 1;
  }

  & > .CommonWidth {
    height: 100%;

    & > .CommonWidth__inner {
      height: 100%;
    }
  }
}

.SpHeaderBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SpHeaderLogo {
  // width: 218px;
  height: 100%;
  display: flex;
  align-items: center;

  h1 {
    display: flex;
    align-items: center;
    padding: 15px 0;
    position: relative;
  }

  svg {
    transition: fill 0.5s $ease-out-expo;
    fill: #fff;
  }
}

.SpHeaderLogo__img {
  width: 21px;//30px;
  height: 21px;//auto;//31px;
  transition: 0.5s $ease-out-expo;
}

.SpHeaderLogo__txt {
  width: 123px;//177px;
  height: 17px;//23px;
  margin-left: 7px;
  transition: 0.5s $ease-out-expo;
}

.SpHeaderLogo__sub {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 15px;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  pointer-events: none;
  letter-spacing: 0.1em;
  transition: 0.5s $ease-out-expo;
}

.SpHeaderLogo__hotel {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  pointer-events: none;
  letter-spacing: 0.1em;
  transition: 0.5s $ease-out-expo;
  opacity: 0;
  margin-top: 1px;

  svg {
    width: 50px;
    height: 12px;
    display: block;
    fill: #fff;
  }
}




/*
.SpHeaderLogo {
  width: 150px;
  height: 100%;

  a {
    height: 100%;
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
      color: #999;
      font-size: 10px;
      display: inline-block;
      margin-left: 10px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}
*/

.ua-sp {
  .SpHeader {
    display: block;
  }
}

.BtnSpToggleMenu {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  right: 0;
}


.BtnSpToggleMenu__icon {
  width: 18px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before,
  &::after {
    display: block;
    position: absolute;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 1px;
    transition: 0.4s $ease-out-expo;
  }

  &::before,
  &::after {
    content: '';
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.BtnSpToggleMenu.-open-menu {
  .BtnSpToggleMenu__icon {
    &::before,
    &::after {
      background-color: #333;
    }
    &::before {
      top: calc(50% - 1px);
      transform: rotate(45deg);
    }
    &::after {
      bottom: 50%;
      transform: rotate(-45deg);
    }
  }
}

.BtnSpOpenLoginModal {
  @extend .font-def--medium;

  position: absolute;
  right: 56px;
  top: 0;
  color: #666;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  padding-left: 20px;
  padding-right: 20px;
  line-height: 56px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s $ease-out-expo;
}


.SpHeader--transparent {
  .SpHeaderBgColor--navy,
  .SpHeaderBgColor--white,
  .SpHeaderBgColor--hotel {
    opacity: 0;
  }
}

.SpHeader--white {
  .SpHeaderBgColor--white {
    opacity: 1;
  }
  .SpHeaderBgColor--navy,
  .SpHeaderBgColor--hotel {
    opacity: 0;
  }

  svg {
    fill: #111;
  }

  .SpHeaderLogo__sub {
    color: #999;
  }

  .BtnSpToggleMenu__icon {
    &::before,
    &::after {
      background-color: #333;
    }
  }
}

.SpHeader--hotel {
  .SpHeaderBgColor--hotel {
    opacity: 1;
  }
  .SpHeaderBgColor--navy,
  .SpHeaderBgColor--white {
    opacity: 0;
  }
}

.SpHeader--hide {
  top: -56px;
}

body.post-type-archive-hotel,
body.single-hotel {
  .SpHeaderLogo__sub {
    opacity: 0;
  }

  .SpHeaderLogo__hotel {
    opacity: 1;
  }
}

.ua-sp {
  body.open-sp-menu {
    .SpHeader {

      .SpHeaderBgColor--login {
        opacity: 1;
      }

      .SpHeaderBgColor--navy {
        opacity: 0;
      }
      .SpHeaderBgColor--white,
      .SpHeaderBgColor--hotel {
        opacity: 0;
      }
    }
    .SpHeaderLogo {
      svg {
        fill: #333;
      }
    }

    .SpHeaderLogo__sub {
      opacity: 0;
    }

    .SpHeaderLogo__hotel {
      opacity: 0;
    }
    .BtnSpOpenLoginModal {
      opacity: 1;
      pointer-events: auto;
    }
  }
}



.SpMenu {
  transform: translate3d(0, 0, 0);
  z-index: 100;
  display: none;
  height: calc(100% - 56px);
  width: 100%;
  position: fixed;
  top: 56px;
  left: 0;
  overflow: hidden;
}

.SpMenu__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 100%;
  left: 0;
  background-color: $color-navy;
}

.SpMenuScroller {
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-y: scroll;
}

.SpMenuBody {
  position: relative;
}

.SpMenu__logo {
  margin-bottom: 30px;

  img {
    width: 39vw;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.SpMenuNav {
  margin-left: -16px;
  margin-right: -16px;
}

html:not(.ua-sp) {
  .SpMenuNav {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.SpMeuNavItem {
  height: 50vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;

  span {
    @extend .font-def--medium;
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 20px;
    left: 18px;
  }

  img {
    position: absolute;
    bottom: 20px;
    left: 18px;
    width: 22.22vw;
    height: auto;
  }
}

html:not(.ua-sp) {
  .SpMeuNavItem {
    @include res('<', 960) {
      span {
        font-size: 20px;
        bottom: 45px;
        left: 35px;
      }
      img {
        width: 15vw;
        bottom: 40px;
        left: 38px;
      }
    }
  }
}

.SpMeuNavItem--search {
  background-image: url('../images/common/SpMenuImg--search.jpg');
}

.SpMeuNavItem--areas {
  background-image: url('../images/common/SpMenuImg--areas.jpg');
}

.SpMeuNavItem--feature {
  background-image: url('../images/common/SpMenuImg--feature.jpg');
}

.SpMeuNavItem--hotel {
  background-image: url('../images/common/SpMenuImg--hotel.jpg');
}

.SpMenu__bottom {
  background-color: #F9F7F5;
  padding: 40px 0 0 0;
}

.SpSubmenu {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.SpSubmenu--footer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.SpSubmenu__primary {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  a {
    @extend .font-def--medium;
    font-size: 14px;
    color: #666;

    .icon-blank {
      display: inline-block;
      width: 14px;
      height: 14px;
      fill: #666;
      vertical-align: middle;
      margin-top: -3px;
    }

    &:not(:first-child) {
      margin-left: 12px;
    }
  }
}

.SpSubmenu__primary--footer {
  a {
    color: #fff;

    .icon-blank {
      display: inline-block;
      width: 14px;
      height: 14px;
      fill: #fff;
      vertical-align: middle;
      margin-top: -3px;
    }

    &:not(:first-child) {
      margin-left: 12px;
    }
  }
}

.SpSubmenu__secondary {
  display: flex;
  justify-content: center;

  a {
    @extend .font-def--regular;
    font-size: 10px;
    color: #999;

    &:not(:first-child) {
      margin-left: 17px;
    }
  }
}

.SpMenuOther {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
}

.SpMenuOther__col {
  width: 50%;
  display: flex;

  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }

}

.BtnOpenRelateSp {
  @extend .font-def--bold;

  display: inline-block;
  // background-color: #fff;
  // height: 24px;
  // line-height: 24px;
  color: #999;
  // padding-left: 11px;
  // padding-right: 11px;
  font-size: 1.2rem;
  // border-radius: 12px 12px;

  &::after {
    content: '';
    width: 8px;
    height: 4px;
    display: inline-block;
    box-sizing: border-box;
    border-bottom: 4px solid #999;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 0 solid rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin-top: -2px;
  }
}

.SpMenuSocial {
  display: flex;
  justify-content: flex-end;

  a {
    width: 32px;
    height: 32px;
    position: relative;
    display: block;

    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #333;
    }

    &[data-social="facebook"] {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &[data-social="instagram"] {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.SpMenuSocial--footer {
  a {
    svg {
      fill: #fff;
    }
  }
}

.SpCopyright {
  @extend .font-def--regular;
  font-size: 10px;
  color: #aaa;
  padding: 40px 16px 40px 16px;
}

