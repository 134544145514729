html:not(.ua-sp) {
  @include res('<', 960) {


    body {
      overflow-x: scroll;
      width: 960px;
    }
  }
}

.wrapper {
  // padding: 100px;
  padding-bottom: 0;//160px;
  position: relative;

  @include res('<', 960) {
    // overflow-x: hidden;
    // padding-top: 128px;
  }

  @include res('sp') {
    padding-bottom: 80px;
    padding-top: 56px;
    // overflow-x: hidden;
    // transform: translate3d(0, 0, 0);
    // will-change: transform;
  }
}

.ua-sp {
  .wrapper {
    overflow-x: hidden;
  }
  body.single-areas {
    .wrapper {
      padding-top: 0;
    }
  }
}

/*
.wrapper.-next {
  opacity: 0;
}
*/
.RemainElement:nth-child(2) {
  opacity: 0;
}


/*
body:not(.home):not(.post-type-archive-hotels):not(.single-areas):not(.single-rooms):not(.single-hotels) {
  .wrapper {
    padding-top: 112px;

    @include res('<', 960) {
      padding-top: 128px;
    }

    @include res('sp') {
      padding-top: 56px;
    }
  }
}
*/

.wrapper--has-padding {
  padding-top: 112px;//56px;//72px;

  @include res('<', 960) {
    padding-top: 128px;
  }

  @include res('sp') {
    padding-top: 56px;
  }
}

.ua-sp {
  .wrapper--home {
    padding-bottom: 0;
  }

  .wrapper--sp-no-padding-top {
    padding-top: 0;
  }
  .wrapper--sp-no-padding-bottom {
    padding-bottom: 0 !important;
  }
}

html:not(.ua-sp) {
  .wrapper--has-padding {
    // padding-top: 112px;//56px;//72px;

    @include res('<', 960) {
      padding-top: 56px;//112px;//128px;
    }

    @include res('sp') {
      // padding-top: 56px;
    }
  }
}



.wrapper--open-relate {
}

body.single-rooms {
  .wrapper {
    // transition: padding-top 0.5s $ease-out-expo;
  }
  /*
  .wrapper--has-padding {
    padding-top: 176px;

    @include res('<', 960) {
      padding-top: 128px;
    }

    @include res('sp') {
      padding-top: 56px;
    }
  }
  */


  .wrapper--open-relate {
    padding-top: 56px;//120px;
  }

}

.barba-wrapper {
  // z-index: 1;
  position: relative;
}

.TransitionMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 19;
  opacity: 0;
  transition: opacity 0.8s $ease-in-out-quint;
  pointer-events: none;
}

.TransitionMask.-show {
  transition: opacity 0.3s $ease-out-expo;
  opacity: 1;
}

.CommonWidth {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 56px;
  padding-right: 56px;
  position: relative;

  @include res('<', 960) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include res('sp') {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.CommonWidth--portal {
  max-width: 1066px;

  @include res('<', 960) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include res('sp') {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.CommonWidth--faq {
  max-width: 1092px;
}

.CommonWidth__inner {
  position: relative;
}

.ArticleGridWidth {
  // list-style: none;
  padding-left: calc(calc(100% - 168px) / 8 + 24px);
  padding-right: calc(calc(100% - 168px) / 8 + 24px);
  margin-bottom: 80px;
  // margin-left: auto;
  // margin-right: auto;

  /*
  @include res('sp') {
    padding-left: 0;
    padding-right: 0;
  }
  */
}

.ArticleGridWidth--no-margin-bottom {
  margin-bottom: 0;
}

.ArticleGridWidth--no-sp-padding {
  @include res('sp') {
    padding-left: 0;
    padding-right: 0;

    .ArticleGridWidth__inner {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

.ArticleGridWidth__inner {
  // padding-left: calc(calc(100% - 120px) / 12);
  // padding-right: calc(calc(100% - 120px) / 12);
  padding-left: 40px;
  padding-right: 40px;

  @include res('sp') {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 37px;
  }
}

.ContentWidth {
  margin-left: auto;
  margin-right: auto;
}

.ContentWidth--min {
  max-width: calc(calc(100% - 168px) / 8 * 4 + 72px);
  padding-left: 40px;
  padding-right: 40px;

  @include res('<', 1080) {
    padding-left: calc(calc(100% - 168px) / 8 + 24px);
    padding-right: calc(calc(100% - 168px) / 8 + 24px);
    width: 100%;
    max-width: initial;
    max-width: auto;
  }

  @include res('sp') {
    padding-left: 0;
    padding-right: 0;
  }
}

.ContentWidth__inner {
  position: relative;

  @include res('<', 1080) {
    padding-left: calc(calc(100% - 120px) / 12);
    padding-right: calc(calc(100% - 120px) / 12);
  }

  @include res('sp') {
    padding-left: 0;
    padding-right: 0;
  }
}

.ContentMarginWrap {
  padding-bottom: 120px;

  @include res('sp') {
    padding-bottom: 60px;
  }
}

.ContentMarginWrap--has-padding-top {
  padding-top: 90px;

  @include res('sp') {
    padding-top: 45px;
  }
}

.Grid {
  position: relative;
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
}

.Grid--sp-break {
  @include res('sp') {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;

    .Grid__col {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.Grid--space-between {
  justify-content: space-between;
}

.Grid--center {
  justify-content: center;
}

.Grid--right {
  justify-content: flex-end;
}

.Grid--col2 {
  flex-wrap: wrap;
  margin-bottom: -24px;

  .Grid__col {
    width: calc(50% - 24px);
    margin-bottom: 24px;
  }
}

.Grid--col3 {
  flex-wrap: wrap;
  margin-bottom: -24px;

  .Grid__col {
    width: calc(33.33% - 24px);
    margin-bottom: 24px;
  }
}

.Grid--col4 {
  flex-wrap: wrap;
  margin-bottom: -24px;

  .Grid__col {
    width: calc(25% - 24px);
    margin-bottom: 24px;
  }
}

.Grid__col {
  margin-left: 12px;
  margin-right: 12px;
  box-sizing: border-box;
  height: 100%;
}

.Grid__col--1 {
  width: calc((100% - 192px) / 8 * 1);
}

.Grid__col--2 {
  width: calc((100% - 192px) / 8 * 2 + 24px);
}

.Grid__col--3 {
  width: calc((100% - 192px) / 8 * 3 + 48px);
}

.Grid__col--4 {
  width: calc((100% - 192px) / 8 * 4 + 72px);
}

.Grid__col--5 {
  width: calc((100% - 192px) / 8 * 5 + 96px);
}

.Grid__col--6 {
  width: calc((100% - 192px) / 8 * 6 + 120px);
}

.Grid__col--7 {
  width: calc((100% - 192px) / 8 * 7 + 144px);
}
