.VisualLocalNav {
  margin-bottom: 130px;
  padding: 80px 0;
  background-color: #080822;
}

.VisualLocalNavList {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
}

.VisualLocalNavListItem {
  margin-left: 2px;
  margin-right: 2px;
  width: 100%;
}

.VisualLocalNavListItem__inner {
  padding-top: 100%;
  background-color: #223;
  position: relative;
  display: block;
  width: 100%;
  border-radius: 2px 2px;
  overflow: hidden;

  &:not([data-touch]) {
    // transition: background-color 0.6s $ease-out-expo;

    .VisualLocalNavListItem__bg {
      transition: transform 0.6s $ease-out-expo, opacity 0.6s $ease-out-expo;
    }

    .VisualLocalNavListItem__title {
      span::after {
        transition: opacity 0.6s $ease-out-expo;
      }
    }

    &:hover {
      // transition: background-color 0.2s $ease-out-expo;

      .VisualLocalNavListItem__bg {
        transition: transform 0.2s $ease-out-expo, opacity 0.2s $ease-out-expo;
        opacity: 0.8;
        transform: scale(1.05);
      }

      .VisualLocalNavListItem__title {
        span::after {
          transition: opacity 0.2s $ease-out-expo;
          opacity: 1;
        }
      }
    }
  }
}

.VisualLocalNavListItem__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transform: scale(1.01);
}

.VisualLocalNavListItem__shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 60%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 60%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
  opacity: 0.5;
}

.VisualLocalNavListItem__title {
  @extend .font-def--medium;

  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 0 10px;
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;

  span {
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-color: #fff;
      opacity: 0;
    }
  }
}
.ua-sp {
  .VisualLocalNavListItem__title {
    font-size: 12px;
    bottom: 12px;
  }
}

.VisualLocalNavScroller {

}

.ua-sp {
  .VisualLocalNav {
    margin-left: -16px;
    margin-right: -16px;
    width: 100vw;
    padding: 24px 0 9px 0;
    margin-bottom: 21px;
  }
  .VisualLocalNavScroller {
    overflow-y: visible;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 15px;
  }

  .VisualLocalNavList {
    width: max-content;
    padding-left: 22px;
    padding-right: 22px;
  }
  .VisualLocalNavListItem {
    width: 33.33vw;
    min-width: 33.33vw;
  }
}
