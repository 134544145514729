.Firstview {
  height: 80vh;//100vh;
  transform: translate3d(0, 0, 0);
  z-index: 2;
  position: relative;
}

html:not(.ua-sp) {
  .Firstview {
    @include res('<', 960) {
      height: 50vh;
    }
  }
}

@media screen and (orientation: landscape) {
   /* 横向きの場合のスタイル */

   html:not(.ua-sp) {
    .Firstview {
      @include res('<', 960) {
        height: 85vh;
      }
    }
  }
}

.Firstview--exclude-header {
  height: calc(90vh - 112px);
}

.Firstview--single-rooms {
  opacity: 0;
}

.ua-sp {
  .Firstview {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }
  .Firstview--sp-home {
    height: 67.22vw;//22222
  }

  .Firstview--hotel-archive {
    height: 66.66vw;
    overflow: hidden;

    .Firstview__dummy {
      background-position: top;
      transform: scale(1.6);
    }

    .Firstview__gradation {
      opacity: 1;

      &::before {
        height: 0;
      }
      &::after {
        height: 220px;
      }
    }
  }

  .Firstview--areas-single {
    height: 100vw;
  }
}


.Firstview__gradation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 200px;
    display: block;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+40,000000+100&1+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 40%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.6) 40%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    opacity: 0.2;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 220px;
    display: block;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+60,000000+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 60%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 60%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    opacity: 0.8;
  }
}

.Firstview__gradation--no-top {
  &::before {
    display: none;
  }
}

.Firstview__dummy {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.Firstview__bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
