/*
.Tag {
  display: block;
  color: #fff;
  background: rgba(153, 153, 153, 0.6);
  height: 32px;
  line-height: 31px;
  border-radius: 16px 16px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @include res('sp') {
    height: 20px;
    line-height: 19px;
    border-radius: 10px 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;

    &:not(:last-child) {
      margin-right: 4px;
    }

    margin-bottom: 4px;
  }
}
*/

.Tag {
  display: block;
  color: rgba(153, 153, 153, 0.9);
  background: rgba(0, 0, 0, 0.05);
  height: 40px;
  line-height: 39px;
  border-radius: 20px 20px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @include res('sp') {
    height: 20px;
    line-height: 19px;
    border-radius: 10px 10px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 10px;

    &:not(:last-child) {
      margin-right: 4px;
    }

    margin-bottom: 4px;
  }
}

.Tag__icon {
  display: inline-block;
  vertical-align: middle;
  fill: rgba(153, 153, 153, 0.9);//#fff;
}

.Tag__icon--hash {
  width: 12px;
  height: 12px;
  margin-top: -3px;
  margin-right: 3px;
}

.ua-sp {
  .Tag__icon--hash {
    width: 10px;
    height: 10px;
    margin-top: -1px;
  }
}

.Tag.-current {
  background-color: #3F1E18;
  color: #fff;
}

.Tag.Tag--hotel.-current {
  background-color: #5E3627;
}

.TagCloud {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;

  @include res('sp') {
    margin-bottom: -4px;
  }
}

.TagCloud--centered {
  justify-content: center;
}

.TagCloud--on-card {
  margin-bottom: -8px;

  .Tag {
    height: 18px;
    line-height: 17px;
    border-radius: 9px 9px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 10px;
    margin-bottom: 8px;

    /*
    &:not(:last-child) {
      margin-right: 8px;
    }
    */
  }

  .Tag:not(:last-child) {
    margin-right: 8px;
  }

  .Tag__icon--hash {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
}

.TagCloud--on-full-card {
  .Tag {
    height: 32px;
    line-height: 31px;
    border-radius: 16px 16px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
  }
}

.TagCloud--rect-tag {
  .Tag {
    border-radius: 2px 2px;
  }
}

.TagCloud--small-tag {
  .Tag {
    height: 24px;
    line-height: 23px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include res('sp') {
    .Tag {
      height: 20px;
      line-height: 19px;
    }
  }
}

.ua-desktop {
  a.Tag {
    transition: background-color 0.6s $ease-out-expo;

    &:hover {
      color: #fff;
      transition: background-color 0.1s;
      background-color: #3F1E18;

      .Tag__icon {
        fill: #fff;
      }
    }
  }
  a.Tag--hotel {
    &:hover {
      background-color: #5E3627;
    }
  }
}
