html {
  font-size: ( $font-size / 16 ) * 100%;
  height: 100%;
}

body {
  @extend .font-def--regular;

  font-size: 14px;
  font-size: 1.4rem;
  background-color: #fff;
  color: #000;
  height: 100%;
  letter-spacing: 0.01em;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-text-size-adjust: none;
}
