
@mixin res( $mode :'', $a : 0, $b : 0 ) {

  // SP & TB
  @if $mode == '' {
    @media screen and ( max-width: $bp-sp + px ) {
      @content;
    }

    @media screen and ( min-width: $bp-sp + 1px ) and ( max-width: $bp-pc + px ) {
      @content;
    }

  }


  @if $mode == 'sp' {
    @media screen and ( max-width: $bp-sp + px ) {
      @content;
    }
  }


  @if $mode == 'tb' {
    @media screen and ( min-width: $bp-sp + 1px ) and ( max-width: $bp-pc + px ) {
      @content;
    }
  }


  @if $mode == 'pc' {
    @media screen and ( min-width: $bp-pc + 1px ) {
      @content;
    }
  }


  @else if $mode == '~' {
    @media screen and ( min-width: $a + px ) and ( max-width: $b + px ) {
      @content;
    }
  }


  @else if $mode == '>' {
    @media screen and ( min-width: $a + px ) {
      @content;
    }
  }


  @else if $mode == '<' {
    @media screen and ( max-width: $a + px ) {
      @content;
    }
  }

}

