/* ============================================================================

  ボタン 丸型

============================================================================== */
.RoundBtn {
  position: relative;
  display: block;
  appearance: none;
  width: 50px;
  height: 50px;
  border-radius: 50% 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;

  span {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  @include res('sp') {
    width: 34px;
    height: 34px;
  }
}

.RoundBtn--no-border {
  border: 1px solid rgba(0, 0, 0, 0);
}

.RoundBtn--32 {
  width: 34px;
  height: 34px;

  @include res('sp') {
    width: 26px;
    height: 26px;
  }
}

.RoundBtn--close {
  span {
    &::before,
    &::after {
      z-index: 2;
      content: '';
      display: block;
      width: 18px;
      height: 2px;
      background-color: #aaa;
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 9px);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.RoundBtn--prev {
  span {
    background-image: url('../images/common/icons/arrow-circle-right.png');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
  }
}

.RoundBtn--next {
  span {
    background-image: url('../images/common/icons/arrow-circle-right.png');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.ua-desktop {
  .RoundBtn {
    span {
      transition: background-color 0.8s $ease-out-expo, opacity 0.5s $ease-out-sine;
    }

    &:hover {
      span {
        transition: background-color 0.1s $ease-out-expo, opacity 0.5s  $ease-out-sine;
        background-color: #f6f6f6;
      }
    }
  }

  .RoundBtn--hover-invert {
    &:hover {
      span {
        transition: background-color 0.1s $ease-out-expo, opacity 0.5s  $ease-out-sine;
        background-color: #080822;
      }
    }
  }
}

/* ============================================================================

  ボタン 角型

============================================================================== */

.RectBtn {
  @extend .font-def--medium;

  display: inline-block;
  border-radius: 4px 4px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  height: 64px;
  line-height: 62px;
  position: relative;
  overflow: hidden;
  // border: 1px solid #080822;
  // transform: translate3d(0, 0, 0);

  .arrow {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    margin-top: -2px;
    fill: #fff;
    margin-right: 5px;
  }

  @include res('sp') {
    height: 48px;
    line-height: 46px;
    font-size: 12px;
    font-size: 1.2rem;

    .arrow {
      width: 11px;
      height: 11px;
    }
  }

  &::after {
    content: '';
    display: block;
    border: 1px solid #080822;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 4px;
    box-sizing: border-box;
  }
}

.RectBtn__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #080822;
  top: 0;
  left: 0;
  transform: scale(1.05, 1.05);//, translate3d(0, 0, 0);
}

.RectBtn__txt {
  position: relative;
}

.RectBtn--48 {
  height: 48px;
  line-height: 46px;

  .arrow {
    margin-top: -4px;
  }
}

.RectBtn--sp-40 {
  @include res('sp') {
    height: 40px;
    line-height: 38px;
  }
}

.ua-desktop {
  .RectBtn {
    transition: color 0.3s $ease-out-sine;

    .arrow {
      transition: fill 0.3s $ease-out-sine;
    }

    .RectBtn__bg {
      transition: background-color 0.3s $ease-out-sine;
    }

    &::after {
      transition: border 0.3s $ease-out-sine;
    }

    &:hover {
      // transition: border 0.1s $ease-out-expo, color 0.1s $ease-out-expo;
      transition: color 0.1s $ease-out-expo;
      // border: 1px solid rgba(0, 0, 0, 0.15);
      color: #333;

      .arrow {
        transition: fill 0.1s $ease-out-expo;
        fill: #333;
      }

      .RectBtn__bg {
        transition: background-color 0.1s $ease-out-expo;
        background-color: #f6f6f6;
      }

      &::after {
        transition: border 0.1s $ease-out-expo;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
  }
}




.RoundedBtn {
  @extend .font-def--medium;

  display: inline-block;
  border-radius: 20px 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  height: 40px;
  line-height: 38px;
  position: relative;
  overflow: hidden;
  // border: 1px solid #080822;
  // transform: translate3d(0, 0, 0);
  position: relative;
  -webkit-appearance: none;

  /*
  .arrow {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    margin-top: -2px;
    fill: #fff;
    margin-right: 5px;
  }
  */

  /*
  @include res('sp') {
    height: 48px;
    line-height: 46px;
    font-size: 12px;
    font-size: 1.2rem;

    .arrow {
      width: 11px;
      height: 11px;
    }
  }
  */

  &::after {
    content: '';
    display: block;
    border: 1px solid #080822;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px 20px;
    box-sizing: border-box;
  }

  &:not([data-touch]) {
    transition: color 0.8s $ease-out-expo;

    &::after {
      transition: border 0.8s $ease-out-expo;
    }

    .RoundedBtn__bg {
      transition: background-color 0.8s $ease-out-expo;
    }

    &:hover {
      transition: color 0.1s $ease-out-expo;
      color: #999;

      &::after {
        transition: border 0.1s $ease-out-expo;
        border: 1px solid rgba(8, 8, 34, 0.1);//#999;
      }

      .RoundedBtn__bg {
        transition: background-color 0.1s $ease-out-expo;
        background-color: #fff;
      }

      .RoundedBtn__txt--blank svg {
        fill: #999;
      }
    }
  }
}

.RoundedBtn--32 {
  height: 32px;
  line-height: 30px;
  border-radius: 16px 16px;
  padding-left: 16px;
  padding-right: 16px;

  &::after {
    border-radius: 16px 16px;
  }
}

.RoundedBtn--40 {
  height: 40px;
  line-height: 38px;
  border-radius: 20px 20px;
  padding-left: 20px;
  padding-right: 20px;

  &::after {
    border-radius: 20px 20px;
  }
}

.RoundedBtn--48 {
  height: 48px;
  line-height: 46px;
  border-radius: 24px 24px;
  padding-left: 24px;
  padding-right: 24px;

  &::after {
    border-radius: 24px 24px;
  }
}


.ua-sp {
  .RoundedBtn--sp-32 {
    height: 32px;
    line-height: 30px;
    border-radius: 16px 16px;
    padding-left: 12px;
    padding-right: 12px;

    &::after {
      border-radius: 16px 16px;
    }

  }
}

.RoundedBtn__txt {
  vertical-align: top;
  position: relative;
  font-size: 12px;
}

.RoundedBtn__txt--arrow {
  &::after {
    content: '';
    width: 3px;
    height: 8px;
    display: inline-block;
    box-sizing: border-box;
    border-left: 3px solid #fff;
    border-top: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid rgba(0, 0, 0, 0);
    border-right: 0 solid rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -2px;
  }
}

.RoundedBtn__txt--blank {
  svg {
    width: 13px;
    height: 13px;
    display: inline-block;
    fill: #fff;
    vertical-align: middle;
    margin: -2px 0 0 2px;
  }
}

.RoundedBtn__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #080822;
  top: 0;
  left: 0;
  transform: scale(1.05, 1.05);//, translate3d(0, 0, 0);
}

.RoundedBtn--red {
  .RoundedBtn__bg {
    background-color: #B24636;
  }
  &::after {
    border: 1px solid #B24636;
  }
}

.RoundedBtn--red:not(.RoundedBtn--booking-disabled) {
  &:not([data-touch]) {
    &::after {
      transition: border 0.8s $ease-out-expo;
    }

    .RoundedBtn__bg {
      transition: background-color 0.8s $ease-out-expo;
    }

    &:hover {
      color: #fff;

      &::after {
        transition: border 0.1s $ease-out-expo;
        border: 1px solid #999;
      }

      .RoundedBtn__bg {
        transition: background-color 0.1s $ease-out-expo;
        background-color: #999;
      }
    }
  }
}

.RoundedBtn--orange {
  .RoundedBtn__bg {
    background-color: #CC5C07;
  }
  &::after {
    border: 1px solid #CC5C07;
  }
}

.RoundedBtn--brown {
  .RoundedBtn__bg {
    background-color: #5E3627;
  }
  &::after {
    border: 1px solid #5E3627;
  }

  &:not([data-touch]) {
    &:hover {
      color: #fff;

      &::after {
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      .RoundedBtn__bg {
        background-color: rgba(94, 54, 39, 0);
      }
    }
  }
}

.RoundedBtn--gray {
  .RoundedBtn__bg {
    background-color: #666;
  }
  .RoundedBtn__txt {
    color: #fff;
  }
  &::after {
    border: 1px solid #666;
  }

  &:not([data-touch]) {
    &:hover {
      color: #333;

      &::after {
        border: 1px solid #666;
      }
      .RoundedBtn__txt {
        color: #333;
      }

      .RoundedBtn__bg {
        background-color: rgba(94, 54, 39, 0);
      }
    }
  }
}



.RoundedBtn--booking-disabled {
  pointer-events: none;

  .RoundedBtn__bg {
    background-color: #666;
  }
  &::after {
    border: 1px solid #666;
  }
}

.RoundedBtn--transparent-black {
  .RoundedBtn__bg {
    background-color: rgba(0, 0, 0, 0);
  }
  .RoundedBtn__txt {
    color: #999;

    &::after {
      border-left: 3px solid #999;
    }
  }
  &::after {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:not([data-touch]) {
    .RoundedBtn__bg {
      transition: background-color 0.8s $ease-out-expo;
    }

    &:hover {
      .RoundedBtn__bg {
        transition: background-color 0.1s $ease-out-expo;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.RoundedBtn--transparent-white {
  .RoundedBtn__bg {
    background-color: rgba(0, 0, 0, 0);
  }
  &::after {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  &:not([data-touch]) {
    .RoundedBtn__bg {
      transition: background-color 0.8s $ease-out-expo;
    }

    .RoundedBtn__txt--arrow {
      &::after {
        transition: border-left 0.8s $ease-out-expo;
      }
    }

    &:hover {
      .RoundedBtn__bg {
        transition: background-color 0.1s $ease-out-expo;
        background-color: rgba(255, 255, 255, 0.05);
      }
      .RoundedBtn__txt--arrow {
        &::after {
          transition: border-left 0.1s $ease-out-expo;
          border-left: 3px solid #999;
        }
      }
    }
  }
}

.RoundedBtn--transparent-hotel {
  .RoundedBtn__bg {
    background-color: rgba(0, 0, 0, 0);
  }
  .RoundedBtn__txt {
    color: #999;

    &::after {
      border-left: 3px solid #999;
    }
  }
  &::after {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }

  &:not([data-touch]) {
    .RoundedBtn__bg {
      transition: background-color 0.8s $ease-out-expo;
    }

    &:hover {
      .RoundedBtn__bg {
        transition: background-color 0.1s $ease-out-expo;
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}

.RoundedBtn__loading {
  width: 16px;
  height: 16px;
  border-radius: 8px 8px;
  border: 2px solid #fff;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 4px;
  border-bottom-color: transparent;
  animation-name: rotation;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.ua-sp {
  .RoundedBtn__loading {
    width: 12px;
    height: 12px;
    margin-left: -4px;
    margin-top: -5px;
    margin-right: 3px;
  }
}
