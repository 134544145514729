.font-size-32 {
  font-size: 32px;
  font-size: 3.2rem;
}

.font-size-22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.font-size-20 {
  font-size: 20px;
  font-size: 2rem;
}

.font-size-18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.font-size-16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.font-size-12 {
  font-size: 12px;
  font-size: 1.2rem;
}
