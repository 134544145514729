/**
 * @license
 * MyFonts Webfont Build ID 3670101, 2018-11-11T20:53:52-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: CarreNoirPro-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/carre-noir/pro-medium-189502/
 * Copyright: Copyright 1996 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3670101
 *
 * © 2018 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/380055");


@font-face {
  font-family: 'CarreNoirPro-Medium';
  src: url('../webfonts/380055_0_0.eot');
  src:
    url('../css/fonts/myfonts/380055_0_0.eot?#iefix') format('embedded-opentype'),
    url('../css/fonts/myfonts/380055_0_0.woff2') format('woff2'),
    url('../css/fonts/myfonts/380055_0_0.woff') format('woff'),
    url('../css/fonts/myfonts/380055_0_0.ttf') format('truetype');
}



// yakuhan
/*! Yaku Han JP (Gothic - All Include) v2.0.0 SIL by Qrac / Based on Noto Sans CJK JP */
// @font-face{font-family:"YakuHanJP";font-style:normal;font-weight:100;src:url("../css/fonts/YakuHanJP/YakuHanJP-Thin.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-Thin.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-Thin.woff") format("woff")}@font-face{font-family:"YakuHanJP";font-style:normal;font-weight:200;src:url("../css/fonts/YakuHanJP/YakuHanJP-Light.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff")}@font-face{font-family:"YakuHanJP";font-style:normal;font-weight:300;src:url("../css/fonts/YakuHanJP/YakuHanJP-DemiLight.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-DemiLight.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-DemiLight.woff") format("woff")}@font-face{font-family:"YakuHanJP";font-style:normal;font-weight:400;src:url("../css/fonts/YakuHanJP/YakuHanJP-Regular.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff")}@font-face{font-family:"YakuHanJP";font-style:normal;font-weight:500;src:url("../css/fonts/YakuHanJP/YakuHanJP-Medium.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff")}@font-face{font-family:"YakuHanJP";font-style:normal;font-weight:700;src:url("../css/fonts/YakuHanJP/YakuHanJP-Bold.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff")}@font-face{font-family:"YakuHanJP";font-style:normal;font-weight:900;src:url("../css/fonts/YakuHanJP/YakuHanJP-Black.eot");src:url("../css/fonts/YakuHanJP/YakuHanJP-Black.woff2") format("woff2"), url("../css/fonts/YakuHanJP/YakuHanJP-Black.woff") format("woff")}

// yakuhan mp
/*! Yaku Han MP (Mincho - All Include) v3.0.0 SIL by Qrac / Based on Noto Serif CJK JP */@font-face{font-family:YakuHanMP;font-style:normal;font-weight:100;src:url(../css/fonts/YakuHanMP/YakuHanMP-ExtraLight.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-ExtraLight.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-ExtraLight.woff) format("woff")}@font-face{font-family:YakuHanMP;font-style:normal;font-weight:200;src:url(../css/fonts/YakuHanMP/YakuHanMP-Light.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-Light.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-Light.woff) format("woff")}@font-face{font-family:YakuHanMP;font-style:normal;font-weight:300;src:url(../css/fonts/YakuHanMP/YakuHanMP-Regular.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-Regular.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-Regular.woff) format("woff")}@font-face{font-family:YakuHanMP;font-style:normal;font-weight:400;src:url(../css/fonts/YakuHanMP/YakuHanMP-Medium.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-Medium.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-Medium.woff) format("woff")}@font-face{font-family:YakuHanMP;font-style:normal;font-weight:500;src:url(../css/fonts/YakuHanMP/YakuHanMP-SemiBold.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-SemiBold.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-SemiBold.woff) format("woff")}@font-face{font-family:YakuHanMP;font-style:normal;font-weight:700;src:url(../css/fonts/YakuHanMP/YakuHanMP-Bold.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-Bold.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-Bold.woff) format("woff")}@font-face{font-family:YakuHanMP;font-style:normal;font-weight:900;src:url(../css/fonts/YakuHanMP/YakuHanMP-Black.eot);src:url(../css/fonts/YakuHanMP/YakuHanMP-Black.woff2) format("woff2"),url(../css/fonts/YakuHanMP/YakuHanMP-Black.woff) format("woff")}

.font-serif {
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
}

.font-gothic {
  font-family: 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

.font-def--regular {
  // font-family: YakuHanJP, 'Noto Sans JP', 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-family: 'Noto Sans JP', 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 400;
}

.font-def--medium {
  // font-family: YakuHanJP, 'Noto Sans JP', 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-family: 'Noto Sans JP', 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 500;
}

.font-def--bold {
  // font-family: YakuHanJP, 'Noto Sans JP', 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-family: 'Noto Sans JP', 'Helvetica Neue', Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 700;
}

.font-serif--light {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 300;
}

.font-serif--medium {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 500;
}

.font-carre {
  font-family: CarreNoirPro-Medium, serif;
  font-weight: normal;
  font-style: normal;
}
