@keyframes img-opacity {
  0% {
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.fadeinObject {
  opacity: 0;
  transition: opacity 0.4s $ease-in-out-sine;

  &.-show {
    opacity: 1;
  }
}

/* ============================================================================

  ヘッダー

============================================================================== */
.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  transition: height 0.5s $ease-out-expo, top 0.5s $ease-out-expo, opacity 1s, background-color 0.5s $ease-out-expo;
  height: 112px;//160px;
  transform: translate3d(0, 0, 0);

  & > .CommonWidth,
  & > .CommonWidth > .CommonWidth__inner,
  & > .CommonWidth > .CommonWidth__inner > .Grid {
    height: 100%;
  }

  & > .CommonWidth > .CommonWidth__inner > .Grid > .Grid__col--2 {
    z-index: 2;
  }

  @include res('<', 1150) {
    & > .CommonWidth {
      padding-left: 40px;
    }
  }

  @include res('<', 960) {
    // height: 128px !important;
  }

  @include res('sp') {
    display: none;
  }
}

.SHeaderLogo {
  width: 218px;
  height: 100%;
  display: flex;
  align-items: center;

  h1 {
    display: flex;
    align-items: center;
    padding: 15px 0;
    position: relative;
  }

  svg {
    fill: #fff;
  }

  &:not([data-touch]) {

    // transition: color 0.6s $ease-out-expo;

    .SHeaderLogo__img,
    .SHeaderLogo__txt {
      transition: opacity 0.6s $ease-out-expo;
    }

    &:hover {

      .SHeaderLogo__img,
      .SHeaderLogo__txt {
        transition: opacity 0.2s $ease-out-expo;
        opacity: 0.8;
      }

      // transition: color 0.2s $ease-out-expo;
      // color: #aaa;
    }
  }
}

.SHeaderLogo__img {
  width: 30px;
  height: 31px;
  transition: 0.5s $ease-out-expo;
}

.SHeaderLogo__txt {
  width: 177px;
  height: 23px;
  margin-left: 10px;
  transition: 0.5s $ease-out-expo;
}

.SHeaderLogo__sub {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 21px;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  letter-spacing: 0.1em;
  transition: opacity 0.5s $ease-out-expo;
}

.SHeaderLogo__hotel {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: 1px;
  margin-left: 5px;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  letter-spacing: 0.1em;
  transition: opacity 0.5s $ease-out-expo;
  opacity: 0;

  svg {
    fill: #fff;
    display: block;
    width: 74px;
    height: 17px;
    transition: fill 0.5s $ease-out-expo;
  }
}

.SHeaderLogo--333 {
  svg {
    fill: #333;
  }

  .SHeaderLogo__sub {
    color: #333;
  }
}

/* ============================================================================

  メニュー

============================================================================== */
.Menu {
  height: 100%;
  position: absolute;
  top: 0;
  right: 32px;
  height: 100%;

  transition: top 0.4s $ease-out-cubic;
}

.MenuList {
  display: flex;
  height: 100%;
}

.MenuListItem {
  margin: 0 40px 0 0;

  @include res('<', 960) {
    margin: 0 20px 0 0;
  }
}

.MenuListItem--current {
  .MenuListItem__btn {
    &::before {
      opacity: 1;
    }
  }
}

.MenuListItem__btn {

  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  transform: translate3d(0, 0, 0);

  &::before {
    content: '';
    height: 6px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.4s;
    background-color: #fff;
  }

  span {
    @extend .font-def--medium;
    position: relative;
    display: block;
    font-size: 16px;

    &::after {
      content: '';
      background-color: #fff;
      width: 100%;
      height: 1px;
      bottom: -3px;
      left: 0;
      position: absolute;
      display: block;
      opacity: 0;
    }
  }

  .icon-blank {
    display: inline-block;
    width: 17px;
    height: 17px;
    fill: #fff;
    vertical-align: middle;
    margin-top: -4px;
  }

  &:not([data-touch]) {

    span::after {
      transition: opacity 0.6s $ease-out-expo;
    }

    &:hover {
      span::after {
        transition: opacity 0.2s $ease-out-expo;
        opacity: 1;
      }
    }
  }
}

.Header--white {
  background-color: #fff;

  .SHeaderLogo {
    svg {
      fill: #333;
    }
  }

  .SHeaderLogo__sub {
    color: #aaa;
  }

  .MenuListItem__btn {
    color: #333;

    .icon-blank {
      fill: #333;
    }

    &::before {
      background-color: #272747;
    }

    span::after {
      background-color: #333;
    }
  }
}

.Header--hotel {
  background-color: #351F1B;
}

.Header--hide {
  top: -56px;
}

.Header--small {
  height: 56px;//72px;

  .SHeaderLogo__img {
    width: 24px;
    height: 25px;
  }

  .SHeaderLogo__txt {
    width: 140px;
    height: 19px;
  }

  .SHeaderLogo__sub {
    opacity: 0;
  }
}

/*
html:not(.ua-sp) {
  .Header {
    @include res('<', 960) {
      background-color: #fff;
      height: 56px;
    }
  }
}
*/

.Header--small:not(.Header--hotel) {
  background-color: #fff;//$color-navy;

  .HeaderLogo {
    .HeaderLogo__rect {
      transition: opacity 0s;
      opacity: 0;
      display: none;
    }

    .HeaderLogo__horizontal {
      display: block;
      opacity: 1;
      animation-name: img-opacity;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-direction: normal;
      animation-iteration-count: 1;
    }
  }

  .SHeaderLogo {
    svg {
      fill: #333;
    }
  }



  .MenuListItem__btn {
    color: #333;

    &::before {
      height: 2px;
      background-color: #272747;
    }

    span::after {
      background-color: #333;
    }
  }
}


.Header--open-relate {
  position: absolute;
  top: 0;
}

body.open-relatepanel.single-rooms {
  .Header {
    // transition: top 0s;
  }
}

body.post-type-archive-hotel,
body.single-hotel {
  .SHeaderLogo__sub {
    opacity: 0;
  }
  .SHeaderLogo__hotel {
    opacity: 1;
  }
}

.Header--open-menu {
  background-color: #F9F7F5 !important;
  top: 0;

  .Menu {
    top: -100%;
  }

  .ModalMenuLinks {
    top: 0;
  }

  .SHeaderLogo {
    svg {
      fill: #333;
    }
  }

  .SHeaderLogo__sub {
    color: #aaa;
  }

  /*
  .MenuListItem__btn {
    color: #333;

    &::before {
      background-color: #272747;
    }
  }
  */
}

/* ============================================================================

  サブメニュー

============================================================================== */
.SubMenu {
  display: flex;
  position: absolute;
  right: 0;
  top: 29px;

  ul {
    display: flex;
    transition: margin-right 0.5s $ease-out-expo;
  }

  li {
    margin-left: 20px;

    a {
      @extend .font-def--bold;

      font-size: 12px;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #fff;
        bottom: 0;
        opacity: 0;
      }

      .icon-blank {
        width: 16px;
        height: 16px;
        display: inline-block;
        fill: #fff;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 2px;
      }
    }
  }

  a.js-open-relate {
    &::after {
      content: '';
      width: 10px;
      height: 5px;
      display: inline-block;
      box-sizing: border-box;
      border-bottom: 5px solid #fff;
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-top: 0 solid rgba(0, 0, 0, 0);
      vertical-align: middle;
      margin-left: 3px;
      margin-top: -2px;
      transition: transform 0.4s $ease-out-expo;
    }
  }

  @include res('<', 1150) {
    li {
      // margin-left: 10px;
    }
  }

  @include res('sp') {
    display: none;
  }
}

.SubMenu--footer {
  top: 0;
}

.HeaderContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 12px 0 0;
}

.HeaderContent {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.SBtnToggleMenu {
  width: 32px;
  height: 32px;
  // background-color: #fff;//rgba(0, 255, 0, 0.1);
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;//#aaa;
    position: absolute;
    left: 50%;//0;
    transform: translate(-50%, 0);
  }

  &::before {
    bottom: 50%;
    margin-bottom: 5px;
  }

  &::after {
    top: 50%;
    margin-top: 5px;
  }

  &:not([data-touch]) {
    &::before,
    &::after {
      transition: 0.6s $ease-out-expo;
    }

    &:hover {

      &::before {
        transition: 0.2s $ease-out-expo;
        margin-bottom: 9px;
      }

      &::after {
        transition: 0.2s $ease-out-expo;
        margin-top: 9px;
      }
    }
  }
}

.Header--small,
.Header--white,
.Header--open-menu {
  .SBtnToggleMenu {
    &::before,
    &::after {
      background-color: #aaa;
    }
  }
}

.Header--open-menu {
  .SBtnToggleMenu {
    &::before {
      transform: translate(-50%, 0) rotate(45deg);
      margin-bottom: 0 !important;
      width: 130%;
    }
    &::after {
      transform: translate(-50%, 0) rotate(-45deg);
      margin-top: 0 !important;
      width: 130%;
    }

    &:not([data-touch]) {
      &:hover {

        &::before {
          width: 90%;
        }

        &::after {
          width: 90%;
        }
      }
    }
  }
}
