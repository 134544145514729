.Pager {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  a {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50% 50%;
    line-height: 39px;
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem;
    color: #aaa;
    margin-left: 5px;
    margin-right: 5px;

    &.-current {
      background-color: #3F1E18;
      color: #fff;
    }
  }

  @include res('sp') {
    a {
      width: 32px;
      height: 32px;
      line-height: 31px;
      font-size: 14px;
      font-size: 1.4rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.Pager__btn {
  &::before {
    width: 9px;
    height: 9px;
    box-sizing: border-box;
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -6px;
    border-left: 2px solid #aaa;
    border-bottom: 2px solid #aaa;
    transform: rotate(45deg);
  }
}

.Pager__btn--next {
  &::before {
    transform: rotate(-135deg);
  }
}
