.SearchModule {
  position: relative;
  padding-bottom: 8px;
  padding-top: 8px;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -50vw;
    width: 100vw;
    height: 100%;
    background-color: #f9f7f5;
  }
}

html:not(.ua-sp) {
  .SearchModule {
    @include res('<', 960) {
      &::before {
        left: 0;
        margin-left: -40px;
        width: calc(100% + 80px);
      }
    }
  }
}

.SearchModule--home {
  padding-bottom: 40px;
  padding-top: 80px;
  transition: padding-top 0.5s $ease-out-expo, padding-bottom 0.5s $ease-out-expo;
}

.SearchModule__lead {
  position: absolute;
  top: 44px;
  left: 0;
  font-size: 16px;
  color: #333;
}

.SearchModule__ui {
  position: relative;
}

.ua-sp {
  .SearchModule:not(.SearchModule--home) {
    padding-top: 12px;
    padding-bottom: 2px;
  }
  .SearchModule--home {
    padding-top: 44px;
    padding-bottom: 14px;
  }
  .SearchModule__lead {
    top: 19px;
    font-size: 14px;
  }
  .SearchModule__ui {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    // padding-bottom: 10px;
    // overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
  }
  /*
  .SearchModule__ui--home {
    margin-right: 0;
    width: calc(100% - 50px);
  }
  */
}

.SearchResult {
  position: relative;
  padding: 107px 0 150px 0;
}

.ua-sp {
  .SearchResult {
    padding: 74px 0 20px 0;
  }
}

.SearchResult__notfound {
  display: none;
}

.LoadingCards {
  display: none;
}

.SearchResult__master {
  display: none;
}

.DummyCards {
  margin-left: -2px;
  margin-right: -2px;
  margin-bottom: -58px;
  display: flex;
  flex-wrap: wrap;
}

.DummyCardsItem {
  margin-left: 2px;
  margin-right: 2px;
  width: calc(25% - 4px);
  margin-bottom: 58px;
}

@keyframes cardloading {
  0% {
    left: -50%;
    // transform: rotate(0deg);
  }
  50% {
    left: 50%;
    // transform: rotate(180deg);
  }
  100% {
    left: 100%;
    // transform: rotate(360deg);
  }
}

.DummyCardsItemThumb {
  padding-top: 49.82%;//69896
  background-color: #F9F7F5;
  border-radius: 2px 2px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    width: 50%;
    height: calc(100% + 40px);
    position: absolute;
    left: 50%;
    top: -20px;
    transform: rotate(10deg);

    background: -moz-linear-gradient(left, rgba(249,247,245,1) 0%, rgba(242,242,242,1) 50%, rgba(249,247,245,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(249,247,245,1) 0%,rgba(242,242,242,1) 50%,rgba(249,247,245,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(249,247,245,1) 0%,rgba(242,242,242,1) 50%,rgba(249,247,245,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    animation-name: cardloading;
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.DummyCardsItem__title {
  width: 75%;
  height: 8px;
  margin-top: 10px;
  background-color: #F9F7F5;
  border-radius: 2px 2px;
}

.DummyCardsItem__desc {
  width: 50%;
  height: 8px;
  margin-top: 10px;
  background-color: #F9F7F5;
  border-radius: 2px 2px;
}


.SearchResultGrid {
  margin-left: -2px;
  margin-right: -2px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -58px;
}

.SearchResultGridItem {
  width: calc(25% - 4px);
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 58px;
}

.ua-sp {
  .DummyCards {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -30px;
  }

  .DummyCardsItem {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .SearchResultGrid {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -30px;
  }

  .SearchResultGridItem {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.RecommendRooms {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.RecommendRooms--show {
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
  pointer-events: auto;
}


/* ============================================================================

  スティッキー検索ボックス

============================================================================== */
.SearchHeader {
  position: fixed;
  top: 112px;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 1;//99;
  transition: top 0.5s $ease-out-expo;
}

.ua-sp {
  .SearchHeader {
    z-index: 2;
  }
}

.SearchHeader--open {
  z-index: 99;
}

.SearchHeader.StickyHeader--middle {
  top: 112 - 56px;
}
.SearchHeader.StickyHeader--small {
  top: 112 - 112px;

  .SearchModule {
    &::before {
      background-color: rgba(249, 247, 245, 0.85);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
}

@include res('<', 960) {
  .SearchHeader {
    top: 56px;
  }

  .SearchHeader.StickyHeader--middle {
    top: 56px;
  }

  .SearchHeader.StickyHeader--small {
    top: 0;
  }
}
