.BtnPageTop {
  appearance: none;
  background-color: #aaa;
  width: 100%;

  p {
    text-align: right;
    height: 40px;
    line-height: 40px;
    font-size: 10px;
    color: #fff;
    pointer-events: none;

    span {
      position: relative;
      display: inline-block;
      // border-bottom: 1px solid rgba(255, 255, 255, 0);

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #fff;
        opacity: 0;
        left: 0;
        bottom: 13px;
        display: block;
        position: absolute;
      }
    }

    &::after {
      content: '';
      width: 8px;
      height: 4px;
      display: inline-block;
      box-sizing: border-box;
      border-bottom: 4px solid #fff;
      border-left: 4px solid rgba(0, 0, 0, 0);
      border-right: 4px solid rgba(0, 0, 0, 0);
      border-top: 0 solid rgba(0, 0, 0, 0);
      vertical-align: middle;
      margin-left: 3px;
      margin-top: -2px;
    }
  }
}

.ua-desktop {
  .BtnPageTop {
    transition: background-color 0.6s;

    span {
      &::after {
        transition: 0.6s;
      }
    }

    &:hover {
      transition: background-color 0.1s;
      background-color: #272747;

      span {
        &::after {
          transition: 0.1s;
          opacity: 1;
        }
      }
    }
  }
}

.Footer {
  background-color: #080821;
  padding-top: 60px;
  padding-bottom: 40px;
  opacity: 0;

  a {
    // color: #fff;
  }

  @include res('<', 960) {
    .Grid {
      justify-content: space-between;
    }

    .Grid__col--1 {
      width: calc(calc(100% - 192px) / 8 * 2 + 24px);
      max-width: 140px;
    }

    .Grid__col--7 {
      padding-left: 12%;
      width: calc(calc(100% - 192px) / 8 * 6 + 120px);
    }
  }

  @include res('sp') {
    padding-top: 50px;
    padding-bottom: 0;

    .Grid__col--1 {
      position: relative;
      padding-left: 48px;
      padding-right: 48px;
      width: 100%;
      max-width: initial;
      max-width: auto;
    }

    .Grid__col--7 {
      padding: 0;
    }
  }
}

.Footer--hotel {
  background-color: #351F1B;
}

.Footer .SubMenu {
  a {
    color: #fff;
  }
}

.ua-desktop {
  .FooterNavCol {
    a {
      transition: color 0.9s $ease-out-expo;
      display: inline-block;
      position: relative;

      &::after {
        transition: opacity 0.9s $ease-out-expo;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0;
      }

      &:not(.FooterNavHead) {
        &::after {
          bottom: 4px;
          background-color: #fff;
        }
      }

      &:hover {
        transition: color 0.1s $ease-out-expo;
        color: #fff;

        &::after {
          transition: opacity 0.1s $ease-out-expo;
          opacity: 1;
        }

        &.FooterNavHead {
          color: #999;
        }
      }
    }
  }
}

.FooterLogo {
  position: relative;
  display: block;

  img {
    width: 100%;
    height: auto;
  }

  .hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include res('<', 960) {
    width: 140px;
  }

  @include res('sp') {
    width: 56%;
    margin-left: auto;
    margin-right: auto;
  }
}

.ua-desktop {
  .FooterLogo {
    .hover {
      transition: opacity 0.9s $ease-out-expo;
    }

    &:hover {
      .hover {
        opacity: 1;
        transition: opacity 0.1s $ease-out-expo;
      }
    }
  }
}

.FooterContent {
  display: flex;
  padding-bottom: 140px;

  // @include res('<', 1200) {
  // padding-right: 80px;
  // }

  @include res('sp') {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;//80px;
  }
}

.FooterNav {
  width: 100%;
  height: 170px;// ティザーのみ暫定

  & > ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & > li {
      &:not(:last-child) {
        margin-right: 100 / 1440 * 30vw;

        @include res('<', 1200) {
          margin-right: 16px;
        }
      }
    }
  }

  @include res('<', 960) {
    & > ul {
      flex-wrap: wrap;
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: -45px;

      & > li {
        margin-left: 16px;
        margin-right: 16px;
        width: calc(50% - 32px);
      }
    }
  }

  @include res('sp') {
    display: none;
  }
}

.FooterNavCol {
  /*
  &:nth-child(1n) {
    flex-grow: 1;
  }
  &:nth-child(2n) {
    flex-grow: 2;
  }
  &:nth-child(3n) {
    flex-grow: 1;
  }
  &:nth-child(4n) {
    flex-grow: 1;
  }
  &:nth-child(5n) {
    flex-grow: 1;
  }
  &:nth-child(6n) {
    flex-grow: 1;
  }
  */

  & > a {
    white-space: nowrap;
  }

  & > ul {
    flex-wrap: wrap;

    & > li {
      width: 100%;
      margin-bottom: 10px;//15px;

      a {
        color: #999;
        font-size: 12px;
        font-size: 1.2rem;
        padding-top: 4px;
        padding-bottom: 4px;
        display: inline-block;
        line-height: 1.4;
      }
    }
  }

  @include res('<', 960) {
    margin-bottom: 45px;

    & > ul {
      & > li {
        margin-bottom: 5px;
      }
    }
  }
}

.FooterNavHead {
  @extend .font-def--bold;

  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 24px;

  &::after {
    background-color: #999;
    bottom: -2px;
  }
}

.FooterCredit {
  position: relative;

  p {
    color: #999;
    font-size: 12px;
    font-size: 1.2rem;
  }

  @include res('sp') {
    text-align: center;

    p {
      font-size: 10px;
      font-size: 1rem;
    }
  }
}

.FooterCredit__nav {
  a {
    text-decoration: none;
    color: #999;
    font-size: 12px;
    font-size: 1.2rem;
    margin-left: 20px;
    display: inline-block;
    position: relative;
  }

  @include res('sp') {
    a {
      font-size: 10px;
      font-size: 1rem;
      margin-left: 0;

      /*
      &:first-child {
        margin-right: 14px;
      }
      */
    }
  }
}

.ua-desktop {
  .FooterCredit__nav {
    a {
      transition: color 0.9s $ease-out-expo;

      &::after {
        transition: opacity 0.9s $ease-out-expo;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0;
        background-color: #fff;
        bottom: -2px;
      }

      &:hover {
        transition: color 0.1s $ease-out-expo;
        color: #fff;

        &::after {
          transition: opacity 0.1s $ease-out-expo;
          opacity: 1;
        }
      }
    }
  }
}

.FooterCredit__nav {
  position: absolute;
  right: 0;
  top: 0;

  @include res('sp') {
    width: 100%;
    bottom: 32px;
    right: auto;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.FooterSocial {
  display: flex;
  align-items: center;
  margin-left: 16px;

  a {
    display: block;

    &:first-child {
      margin-right: 11px;
    }

    &:last-child {
      margin-right: 3px;
    }

    &[data-social="facebook"] {
      width: 24px;
      height: 24px;
    }

    &[data-social="instagram"] {
      width: 18px;
      height: 18px;
    }

    svg {
      fill: #fff;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @include res('sp') {
    // margin-top: 40px;
    // padding-top: 40px;

    a {
      svg {
        fill: #fff;
      }
    }
  }
}

.FooterSocial--modal {
  a {
    svg {
      fill: #999;
    }
  }
}

.ua-desktop {
  .FooterSocial {
    a {
      svg {
        transition: fill 0.9s $ease-out-expo;
      }

      &:hover {
        svg {
          transition: fill 0.1s $ease-out-expo;
          fill: #999;
        }
      }
    }
  }

  .FooterSocial--modal {
    a {
      &:hover {
        svg {
          transition: fill 0.1s $ease-out-expo;
          fill: #333;
        }
      }
    }
  }
}

/*
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
*/

.Loader {
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
}

.Loader__img {
  width: 50px;
  height: 50px;
  border: 3px solid $color-navy;
  border-bottom-color: transparent;
  border-radius: 50% 50%;
  margin: -25px 0 0 -25px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  pointer-events: none;
  animation-name: rotation;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  // box-shadow:0px 0px 6px -1px #fff;
  // box-shadow:0px 0px 0px 1px #fff inset;
}

.FooterMenu {
  position: absolute;
  right: 0;
  top: 0;
}

.FooterMenu__row {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:not(:first-child) {
    margin-top: 25px;
  }
}

.FooterMenuItem {
  @extend .font-def--medium;

  color: #fff;
  font-size: 16px;
  position: relative;
  display: block;
  transition: color .9s cubic-bezier(.19,1,.22,1);

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0;

    background-color: #999;
    bottom: -2px;
  }

  .icon-blank {
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-top: -1px;
    fill: #fff;
  }

  &:not(:first-child) {
    margin-left: 25px;
  }
}

.FooterMenuItem--secondary {
  @extend .font-def--bold;
  font-size: 12px;

  &::before {
    bottom: 0px;
  }
}

.FooterMenuItem--relate {
  &::after {
    content: '';
    width: 8px;
    height: 4px;
    display: inline-block;
    box-sizing: border-box;
    border-bottom: 4px solid #fff;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 0 solid rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin-left: 3px;
    margin-top: -2px;
  }
}

.FooterMenuItem--modal {
  color: #999;

  &::before {
    background-color: #333;
  }

  &::after {
    border-bottom: 4px solid #999;
  }
}

.BtnFooterBooking {
  @extend .font-def--medium;

  display: block;
  height: 22px;
  font-size: 12px;
  color: #080822;
  padding: 0 8px 0 7px;
  background-color: #fff;
  line-height: 21px;
  border-radius: 1px 1px;
  margin-left: 40px;
}

/*
.ua-desktop {
  .FooterNavCol {
    a {
      transition: color 0.9s $ease-out-expo;
      display: inline-block;
      position: relative;

      &::after {
        transition: opacity 0.9s $ease-out-expo;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0;
      }

      &:not(.FooterNavHead) {
        &::after {
          bottom: 4px;
          background-color: #fff;
        }
      }

      &:hover {
        transition: color 0.1s $ease-out-expo;
        color: #fff;

        &::after {
          transition: opacity 0.1s $ease-out-expo;
          opacity: 1;
        }

        &.FooterNavHead {
          color: #999;
        }
      }
    }
  }
}

*/

.FooterSection--bottom {
  display: flex;
  justify-content: space-between;
}

.FooterSubmenu {
  display: flex;

  br {
    display: none;
  }
}

.FooterSubmenuItem {
  @extend .font-def--medium;
  display: block;
  font-size: 12px;
  color: #999;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0;

    background-color: #fff;
    bottom: -2px;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.FooterSubmenuItem--primary {
  @extend .font-def--bold;
  color: #FAFAFA;

  .icon-blank {
    display: inline-block;
    width: 14px;
    height: 14px;
    fill: #FAFAFA;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: 2px;
  }

  &::before {
    background-color: #999;
  }
}

.ua-sp {
  .FooterSection--bottom {
    display: none;
  }
}

.FooterSubmenuItem--modal {
  &::before {
    background-color: #333;
  }
}

.Copyright {
  font-size: 12px;
  color: #666;
}

.ua-desktop {
  .BtnFooterBooking {
    transition: color 0.9s $ease-out-expo, background-color 0.9s $ease-out-expo;

    &:hover {
      transition: color 0.1s $ease-out-expo, background-color 0.1s $ease-out-expo;
      background-color: #080822;
      color: #fff;
    }
  }

  .FooterMenuItem {
    transition: color 0.9s $ease-out-expo;

    &::before {
      transition: opacity 0.9s $ease-out-expo;
    }

    .icon-blank {
      transition: fill 0.9s $ease-out-expo;
    }

    &:hover {
      transition: color 0.1s $ease-out-expo;
      color: #999;

      .icon-blank {
        transition: fill 0.1s $ease-out-expo;
        fill: #999;
      }

      &::before {
        transition: opacity 0.1s $ease-out-expo;
        opacity: 1;
      }
    }
  }

  .FooterMenuItem--relate {
    &::after {
      transition: border-bottom 0.9s $ease-out-expo;
    }
    &:hover {
      &::after {
        transition: border-bottom 0.1s $ease-out-expo;
        border-bottom: 4px solid #999;
      }
    }
  }

  .FooterMenuItem--modal {
    &:hover {
      color: #333;



      &::after {
        border-bottom: 4px solid #333;
      }
    }
  }

  .FooterSubmenuItem {
    transition: color 0.9s $ease-out-expo;

    &::before {
      transition: opacity 0.9s $ease-out-expo;
    }

    svg {
      transition: fill 0.9s $ease-out-expo;
    }

    &:hover {
      transition: color 0.1s $ease-out-expo;
      color: #fff;

      svg {
        transition: fill 0.1s $ease-out-expo;
      }

      &::before {
        transition: opacity 0.1s $ease-out-expo;
        opacity: 1;
      }
    }
  }
  .FooterSubmenuItem--primary {
    &:hover {
      color: #999;

      svg {
        fill: #999;
      }
    }
  }

  .FooterSubmenuItem--modal {
    &:hover {
      color: #333;
    }
  }
}

.FooterImgNav {
  margin-top: 62px;
  display: none;
  // margin-left: -16px;
  // margin-right: -16px;
  width: 100%;//100vw;

  @include res('sp') {
    display: block;
  }
}

.FooterSpContent {
  display: none;

  @include res('sp') {
    display: block;
  }
}

.CommonModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(8, 8, 34, 0.6);
}

.CommonModalScroller {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

.CommonModalPanel {
  border-radius: 16px 16px;
  padding: 22px 32px 40px 32px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.ua-sp {
  .CommonModalPanel {
    border-radius: 0 0;
    padding: 15px 16px 40px 16px;
  }
}

.LoginModal__title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
}

.LoginModalPrimary {
  margin-bottom: 80px;
  background-color: #F9F7F5;
  padding: 40px 24px 24px 24px;
  border-radius: 3px 3px;
}

.LoginModalPrimary__title {
  margin-bottom: 35px;
  text-align: center;
}

.LoginModalSecondary {
  h5 {
    text-align: center;
  }
}

.LoginModalSecondary + .LoginModalSecondary {
  margin-top: 70px;
}

.LoginModalGrid {
  display: flex;
  margin-top: 24px;
}

.LoginModalGrid__col {
  &:first-child {
    width: 200px;
  }
  &:last-child {
    width: calc(100% - 200px);
    padding-left: 24px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.LoginModalRelate {
  margin-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 30px;
}

.LoginModalRelate__title {
  text-align: center;
  margin-bottom: 20px;
}

.LoginModalRelate__btns {
  display: flex;
  justify-content: center;

  a:first-child {
    margin-right: 32px;
  }
}

.ua-sp {
  .LoginModal {
    .CommonWidth {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.BtnCloseLoginModalPanel {
  position: absolute;
  right: 23px;
  top: 23px;
}

.ua-sp {
  .LoginModal__title {
    margin-top: 50px;
  }
  .LoginModal .RTE {
    h3 {
      padding-left: 0 !important;
      font-size: 22px !important;
    }
    p.--small {
      font-size: 12px;
    }
    p.wp-caption-text {
      line-height: 1.5
    }
    p:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  .LoginModalPrimary {
    padding: 30px 16px 15px 16px;
  }
  .LoginModalPrimary__title {
    margin-bottom: 25px;
    h4 {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .LoginModalGrid {
    flex-wrap: wrap;
  }

  .LoginModalGrid__col {
    position: relative;

    &:first-child,
    &:last-child {
      width: 100%;
    }

    &:first-child {
      width: 100vw;
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 50%;
      overflow: hidden;

      img {
        position: absolute;
        top: 55%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
    &:last-child {
      padding-left: 0;
      margin-top: 10px;
    }
  }
  .LoginModalSecondary__btns {
    text-align: center;
  }
  .BtnCloseLoginModalPanel {
    position: fixed;
  }
}
