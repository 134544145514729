.h1 {
  @extend .font-serif--light;

  font-size: 42px;
  font-size: 4.2rem;
  margin-top: 60px;
  margin-bottom: 25px;
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  @include res('sp') {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.RTE {
  h2 {
    @extend .font-serif--light;
  }

  h3 {
    @extend .font-serif--medium;

    .subtitle {
      @extend .font-def--regular;
    }
  }

  h4 {
    @extend .font-serif--medium;
  }

  h5 {
    @extend .font-serif--medium;
  }
}

.RTE {
  color: #333;

  p {
    color: #333;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #111;
  }

  .ProjectContentAccordionCollapse__height {
    h2,
    h3 {
      &:first-child {
        margin-top: 30px;
      }
    }
  }

  h2 {
    font-size: 36px;
    font-size: 3.6rem;
    margin-top: 60px;
    margin-bottom: 25px;
    line-height: 1.5;

    &:first-child {
      margin-top: 0;
    }

    @include res('sp') {
      font-size: 24px;
      font-size: 2.4rem;
    }
  }

  h3 {
    font-size: 26px;
    font-size: 2.6rem;
    margin-top: 60px;
    margin-bottom: 25px;
    line-height: 1.5;

    &:first-child {
      margin-top: 0;
    }

    .subtitle {
      font-size: 12px;
      color: #666;
      display: block;
      margin-top: 8px;
    }

    @include res('sp') {
      font-size: 18px;
      font-size: 1.8rem;
    }
  }

  h2 + h3 {
    margin-top: 40px;

    @include res('sp') {
      margin-top: 20px;
    }
  }

  h4 {
    font-size: 22px;
    font-size: 2.2rem;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0;
    }

    @include res('sp') {
      font-size: 16px;
      font-size: 1.6rem;
    }
  }

  h5 {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 10px;

    @include res('sp') {
      font-size: 16px;
      font-size: 1.6rem;
    }
  }

  p {
    // font-size: 18px;
    font-size: 16px;
    line-height: 1.7;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    a {
      color: #333;
      text-decoration: underline;
    }

    @include res('sp') {
      font-size: 14px;
    }
  }

  p.--small {
    font-size: 14px;
  }

  em {
    font-style: italic;
  }

  .wp-caption {
    width: 100% !important;
    margin-bottom: 50px;
    font-size: 12px;
    line-height: 1.4;
    color: #666;

    img {
      max-width: 100% !important;
      height: auto;
      margin-bottom: 10px;
    }
  }

  p.wp-caption-text {
    // margin-top: 5px;
    font-size: 12px;
    line-height: 1.4;
    color: #666;

    a {
      color: #666;
      text-decoration: underline;
    }

    @include res('sp') {
      font-size: 10px;
      line-height: 1;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.RTE--single {
  p {
    font-size: 16px;
    font-size: 1.6rem;
    color: #333;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  ul:not([class]) {
    padding-left: 23px;

    & > li {
      position: relative;
      font-size: 16px;
      font-size: 1.6rem;
      color: #333;
      line-height: 1.5;

      &:not(:first-child) {
        margin-top: 6px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        left: -12px;
        width: 4px;
        height: 4px;
        border-radius: 2px 2px;
        background-color: #333;
        opacity: 0.6;
      }
    }
  }

  @include res('sp') {
    p {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.75;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    ul:not([class]) {
      li {
        font-size: 14px;
        font-size: 1.4rem;
      }
    }
  }
}

.ua-desktop {
  .RTE {
    p {
      a {
        transition: color 0.7s $ease-out-expo;

        &:hover {
          transition: color 0s;
          color: #666;
        }
      }
    }
  }
}
