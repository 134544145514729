.AfterFadeinArea {
  opacity: 0;
  transition: opacity 0.7s $ease-in-out-sine;
}

.CommonHeader {
  opacity: 0;
  transition: opacity 0.6s $ease-in-out-sine;
  background-color: $color-navy;
  height: 288px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../images/common/hero-pattern.png');
  background-repeat: repeat;

  @include res('sp') {
    height: 144px;
  }
}

.CommonHeader.-fadein {
  opacity: 1;

  .CommonHeaderContent__title {
    opacity: 1;
    transform: scale(1, 1);
  }

  .CommonHeaderContent__description {
    &::before {
      width: 24px;

      @include res('sp') {
        width: 16px;
      }
    }

    span {
      opacity: 1;
    }
  }
}

.CommonHeader--note-inc {
  background-image: url('../images/note-inc/head.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 560px;

  @include res('sp') {
    height: 270px;
  }
}

.CommonHeader--note-institute {
  background-image: url('../images/note-institute/head.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 560px;

  @include res('sp') {
    height: 270px;
  }
}

body.hash-link {
  .CommonHeader.-fadein {
    transition: 0s !important;
    opacity: 1;

    .CommonHeaderContent__title {
      transition: 0s !important;
      opacity: 1;
      transform: scale(1, 1);
    }

    .CommonHeaderContent__description {
      &::before {
        transition: 0s !important;
        width: 24px;

        @include res('sp') {
          width: 16px;
        }
      }

      span {
        transition: 0s !important;
        opacity: 1;
      }
    }
  }
}

.CommonHeaderContent {
  color: #fff;
  text-align: center;
}

.CommonHeaderContent__title {
  @extend .font-carre;

  font-size: 56px;
  font-size: 5.6rem;
  margin-bottom: 0;
  opacity: 0;
  transform: scale(1.12, 1.12);
  transition: 1.2s $ease-in-out-expo 0.1s;

  @include res('sp') {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.CommonHeaderContent__title--ja {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 300;
  font-size: 42px;
  font-size: 4.2rem;

  @include res('sp') {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.CommonHeaderContent__description {
  @extend .font-serif--medium;

  font-size: 16px;
  font-size: 1.6rem;
  transform: translate3d(0, 0, 0);

  &::before {
    content: '';
    width: 0;
    height: 2px;
    background-color: #e6e6e6;
    opacity: 0.15;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 30px;
    transition: width 0.5s $ease-out-expo 1s;
  }

  span {
    opacity: 0;
    transition: opacity 0.5s $ease-in-out-sine 1.1s;
  }

  @include res('sp') {
    font-size: 14px;
    font-size: 1.4rem;

    &::before {
      width: 0;
      margin-top: 18px;
      margin-bottom: 11px;
    }
  }
}

.CommonContentWidth {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.HasFullBg {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -50vw;
    width: 100vw;
    height: 100%;
    background-color: #080822;
  }

  & > * {
    position: relative;
  }
}

html:not(.ua-sp) {
  .HasFullBg {
    @include res('<', 960) {
      &::before {
        width: calc(100% + 80px);
        left: 0;
        margin-left: -40px;
      }
    }
  }
}

.HasFullBg--fff {
  &::before {
    background-color: #fff;
  }
}

.HasFullBg--navy {
  &::before {
    background-color: #080822;
  }
}

.HasFullBg--red {
  &::before {
    background-color: #8a001a;
  }
}

.HasFullBg--f6 {
  &::before {
    background-color: #f6f6f6;
  }
}

.HasFullBg--f3 {
  &::before {
    background-color: #f3f3f3;
  }
}

.HasFullBg--ef {
  &::before {
    background-color: #efefef;
  }
}

.HasFullBg--272747 {
  &::before {
    background-color: #272747;
  }
}

.GridWrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  opacity: 0.6;
  pointer-events: none;
}

.GridContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.DummyGrid {
  position: relative;
  height: 2000px;
  display: flex;
  margin-left: -12px;
  margin-right: -12px;

  span {
    flex: 1;
    display: block;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.3);
    margin-left: 12px;
    margin-right: 12px;
  }
}

.BtnSnsWrap {
  display: flex;
}

.BtnSns {
  width: 96px;
  height: 32px;
  border-radius: 2px 2px;
  line-height: 32px;
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.1em;

  svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    fill: #fff;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 3px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @include res('sp') {
    width: 60px;
    height: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;

    svg {
      width: 11px;
      height: 11px;
      margin-right: 2px;
      margin-top: -1px;
    }
  }
}

.BtnSns[data-type="facebook"] {
  background-color: #3b5998;
}

.BtnSns[data-type="twitter"] {
  background-color: #55acee;
}

.ua-desktop {
  .BtnSns {
    transition: 1.2s $ease-out-expo;

    &:hover {
      transition: 0.1s $ease-out-expo;
      opacity: 0.6;
    }
  }
}

.img-lazy,
.img-lazy-hide {
  opacity: 0;
  transition: opacity 0.5s $ease-in-out-sine;
  background-size: cover;
  background-position: center;

  &.loaded {
    opacity: 1;
  }
}

p {
  svg.icon-arrow {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-left: 4px;
    margin-top: -2px;
    vertical-align: middle;
    fill: #333;
  }
}

.dev-hidden {
  display: none;
}

.HorizontalHeading {
  display: flex;
  color: #111;
  align-items: center;
}

.HorizontalHeading__title {
  @extend .font-serif--light;

  line-height: 1.5;
  font-size: 36px;
}

.HorizontalHeading__description {
  @extend .font-serif--medium;

  display: flex;
  font-size: 16px;
  align-items: center;
  margin-left: 25px;

  &::before {
    content: '';
    width: 1px;
    height: 50px;
    background-color: #111;
    display: block;
    margin-right: 25px;
    // vertical-align: middle;
  }
}

.HorizontalHeading--fff {
  color: #fff;

  .HorizontalHeading__description {
    &::before {
       background-color: #fff;
    }
  }
}

.ua-sp {
  .HorizontalHeading {
    flex-wrap: wrap;
  }

  .HorizontalHeading__title {
    width: 100%;
    font-size: 26px;
  }

  .HorizontalHeading__description {
    width: 100%;
    margin-left: 0;
    font-size: 10px;
    margin-top: 8px;

    &::before {
      display: none;
    }
  }
}

.CardGrid {
  display: flex;
}

.CardGrid--rooms {
  margin-left: -2px;
  margin-right: -2px;
  margin-bottom: -40px;

  .CardGridItem {
    margin-left: 2px;
    margin-right: 2px;
    width: calc(25% - 4px);
    margin-bottom: 40px;
  }
}

.CardGrid--areas-single {
  margin-left: -2px;
  margin-right: -2px;
  margin-bottom: -40px;

  .CardGridItem {
    margin-left: 2px;
    margin-right: 2px;
    width: calc(25% - 4px);
    margin-bottom: 40px;
  }
}

.ua-sp {
  .CardGrid--rooms {
    margin-left: -8px;
    margin-right: -8px;

    .CardGridItem {
      margin-left: 8px;
      margin-right: 8px;
      width: calc(50% - 16px);
    }
  }

  .CardGrid--areas-single {
    margin-left: -8px;
    margin-right: -8px;

    .CardGridItem {
      margin-left: 8px;
      margin-right: 8px;
      width: calc(50% - 16px);
    }
  }
}


/* ============================================================================

  Card

============================================================================== */
.Card {
  display: block;
  background-color: #fff;
  border-radius: 2px 2px;// blog -> 4
  box-sizing: border-box;
  overflow: hidden;
}

.Card--arrange-height {
  height: 100%;
}

.ua-desktop {
  a.Card {
    cursor: pointer;
    transition: box-shadow 0.3s $ease-out-expo;

    &:hover {
      box-shadow: 0 0 0 2px #e6e6e6;
    }
  }
}
