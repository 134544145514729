/* ============================================================================

  関連サイトパネル

============================================================================== */
.RelatePanel {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background-color: #f3f3f3;
}

.RelatePanel__inner {
  position: relative;
}

// .RelatePanelContent {
// }

// .RelatePanelContent__height {
// }

.RelatePanelBlock {
  // padding-top: 86px;
  padding-top: 55px;
}

/* .RelatePanelBlock + .RelatePanelBlock {
  padding-top: 118px;
} */

.RelatePanelBlock--secondary {
  padding-top: 43px;
  padding-bottom: 80px;
}

.RelateGrid {
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
}

.RelateGrid--primary {
  .RelateGrid__col {
    width: calc(50% - 24px);
  }
}

.RelateGrid--secondary {
  margin-left: -4px;
  margin-right: -4px;
  justify-content: center;

  .RelateGrid__col {
    width: calc(25% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }

  @include res('<', 960) {
    flex-wrap: wrap;
    margin-bottom: -24px;

    .RelateGrid__col {
      width: calc(50% - 24px);
      margin-bottom: 24px;
    }
  }

  @include res('sp') {
    margin-left: -16px;
    margin-right: -16px;

    .RelateGrid__col {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}

.RelateGrid__col {
  margin-left: 12px;
  margin-right: 12px;
}

.RelatePanel__title {
  @extend .font-carre;

  font-size: 32px;
  color: #111;
  text-align: center;
  line-height: 1;
  margin-bottom: 40px;

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    background-color: #111;
    opacity: 0.15;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
  }
}

.RelatePanel__title--ja {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, 'Yu Mincho',
    YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 500;
  font-size: 22px;

  &::after {
    margin-top: 20px;
  }
}

/* ============================================================================

  カード内スタイル

============================================================================== */
.RelateCard {
  padding-top: 38px;
  padding-bottom: 26px;
  padding-left: 24px;
  padding-right: 24px;
}

.RelateCard--secondary {
  padding-top: 28px;
  padding-left: 16px;
  padding-right: 16px;

  @include res('sp') {
    padding-top: 29px;
    padding-bottom: 22px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.RelateCardFigure {
  position: relative;
  margin-top: -38px;
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 48px);
  // margin-bottom: 32px;
  margin-bottom: 21px;
}

.ua-sp {
  .RelateCardFigure {
    margin-top: -28px;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    margin-bottom: 20px;
  }
}

.RelateCardFigure__inner {
  display: block;
  position: relative;
  background-color: #e6e6e6;
}

.RelateCardFigure__img {
  // padding-top: 50%;
  padding-top: 38.7%;
}

.RelateCard__title {
  // @extend .font-carre;
  @extend .font-serif--medium;

  // font-size: 26px;
  font-size: 22px;
  line-height: 1;
  color: #111;
  white-space: nowrap;

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    // margin-left: 6px;
    margin-left: 2px;
    margin-top: -5px;
  }

  span {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: rgba(17, 17, 17, 0);
      position: absolute;
      bottom: 4px;
      left: 0;
      transition: 0.3s $ease-out-expo;
    }
  }
}

/* .RelateCard__title--portal {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 500;
  font-size: 26px;

  svg {
    margin-top: -5px;
  }
} */

.RelateCard__title--secondary {
  // font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  // font-weight: 500;
  font-size: 16px;

  svg {
    width: 11px;
    height: 11px;
    margin-left: 5px;
    margin-top: -3px;
  }

  span {
    &::after {
      bottom: 1px;
    }
  }

  @include res('sp') {
    font-size: 16px;
    font-size: 1.6rem;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.RelateCard__body {
  border-top: 4px solid #efefef;
  padding-top: 12px; //24px;
  margin-top: 20px; //18px;

  p {
    color: #333;
    font-size: 14px;
    line-height: 1.75;
  }

  @include res('sp') {
    border-top: 2px solid #efefef;

    p {
      font-size: 12px;
      font-size: 1.2rem;
    }
  }
}

.RelateCard__body--secondary {
  padding-top: 12px;
  margin-top: 19px;

  @include res('sp') {
    padding-top: 15px;
    margin-top: 19px;
  }
}

.ua-desktop {
  a.Card {
    &:hover {
      .RelateCard__title {
        span {
          &::after {
            background-color: rgba(17, 17, 17, 1);
          }
        }
      }
    }
  }
}

.RelatePanel__close {
  position: absolute;
  top: 24px;
  right: 0;
}

.RelatePanelBlock__attention {
  color: #333;
  font-size: 12px;
  margin-top: 14px;

  a {
    color: #333;
    text-decoration: underline;

    &:hover {
      color: #999;
    }
  }
}

.ua-sp {
  .RelatePanelBlock__attention {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 10px;
  }
}

/* ============================================================================

  SP

============================================================================== */
.SpRelatePanel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  display: none;
  z-index: 201;
}

.SpRelatePanel__scroller {
  position: relative;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-y: scroll;
}

.SpRelatePanelContenet {
  position: relative;
  padding-bottom: 40px;
}

.SpRelatePanel__close {
  position: absolute;
  right: 16px;
  top: 16px;
}

.SpRelatePanel__title {
  @extend .font-carre;

  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
  color: #111;
  padding-top: 44px;
  margin-bottom: 24px;

  &::after {
    content: '';
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 16px;
    height: 2px;
    background-color: #000;
    opacity: 0.15;
    margin-top: 20px;
  }
}

.SpRelatePanel__title--ja {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, 'Yu Mincho',
    YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 500;
  font-size: 20px;
  font-size: 2rem;

  &::after {
    margin-top: 27px;
  }
}

.SpRelatePanelList {
  li {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}

.SpRelatePanelListItem {
  background-color: #fff;

  /*
  a {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 28px;
    padding-bottom: 24px;
  }
  */
}

.SpRelatePanelListItem__inner {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 28px;
  padding-bottom: 24px;
}

.SpRelatePanelListItem__title {
  // @extend .font-carre;
  @extend .font-serif--medium;
  font-size: 22px;
  font-size: 2.2rem;
  color: #111;
  line-height: 1;

  svg {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-top: -4px;
    fill: #111;
    margin-left: 6px;
  }
}

/* .SpRelatePanelListItem__title--portal {
  font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 500;

  font-size: 22px;
  font-size: 2.2rem;

  svg {
    margin-top: -4px;
  }
} */

.SpRelatePanelListItem__body {
  border-top: 2px solid #efefef;
  margin-top: 12px;
  padding-top: 17px;

  p {
    font-size: 12px;
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
  }
}

.SpRelatePanelList--secondary {
  .SpRelatePanelListItem__title {
    font-family: YakuHanMP, 'Noto Serif JP', Georgia, 游明朝, 'Yu Mincho',
      YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
    font-weight: 500;
    font-size: 16px;

    svg {
      width: 10px;
      height: 10px;
      margin-top: -4px;
      margin-left: 4px;
    }
  }

  .SpRelatePanelListItem__body {
    margin-top: 20px;
  }
}
